function after_workout_add_hooks() {
  $(document).on("#workout-creator cocoon:after-insert", function(e, added_item) {
    exercises_field_dropdown_change();
  });
}

function exercises_field_dropdown_change() {
  $(".exercise_field").change(function(){
    exercise_field = $(this);
    id = exercise_field.val();
    
    $.ajax('/exercises/' + id, 
    {
        dataType: 'json',
        timeout: 3000,
        success: function (data,status,xhr) {
          how_to = data.how_to
          exercise_field.parents('.nested-fields').find('.how-to-field').val(how_to);
        },
        error: function (jqXhr, textStatus, errorMessage) {
            $('#errors').append("<p class='alert alert-danger'>Nešto je pošlo po zlu!</p>");
        }
    });
}); 
}

function workoutDoneReport() {
  $('#workout-done-modal').on('hidden.bs.modal', function () {
    window.location.replace('/members/dashboard');
  });

  $("#workout-done").click(function () {
    id = $('#workout-done-id').val();

    $.ajax('/workout_plan_day_workouts/' + id,
      {
        dataType: 'json',
        timeout: 3000,
        type: 'PUT',
        data: "id="+id,
        success: function (data, status, xhr) {
           $('#workout-done').fadeOut('slow').remove();
           $('#workout-done-modal').modal('show');
        },
        error: function (jqXhr, textStatus, errorMessage) {
          $('#errors').append("<p class='alert alert-danger'>Pokušavaš napraviti nešto što nije dozvoljeno!</p>");
          window.location.replace('/members/dashboard');
        }
      });
  });
}

$(document).on('turbolinks:load', function(e) {
  after_workout_add_hooks();
  exercises_field_dropdown_change();
  workoutDoneReport();
});
(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory(require('jquery')) :
        typeof define === 'function' && define.amd ? define(['jquery'], factory) :
            (factory(global.jQuery));
}(this, (function ($) {
    'use strict';

    $.fn.datepicker.languages['hr-HR'] = {
        format: 'dd.mm.yyyy',
        days: ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"], 
        daysShort: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"], 
        daysMin: ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"], 
        months: ["Siječanj", "Veljača", "Ožujak", "Travanj", "Svibanj", "Lipanj", "Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"], 
        monthsShort: ["Sij", "Velj", "Ožu", "Tra", "Svi", "Lip", "Srp", "Kol", "Ruj", "Lis", "Stu", "Pro"], 
        today: "Danas",
        weekStart: 1
    };
})));

function measurementsChart() {
    measurementsChartObj = $("#measurements-chart-antro");

    // return unless Chart object for measurements exists
    if(measurementsChartObj.length == 0) {
        return;
    }

    height = {
        label: "Visina",
        data: measurementsChartObj.data('data').height,
        fill: false,
        lineTension: 0,
        borderColor: '##00cc66'
    };

    weight = {
        label: "Masa",
        data: measurementsChartObj.data('data').weight,
        fill: false,
        lineTension: 0,
        borderColor: '#000000'
    };

    fat = {
        label: "Masnoća",
        data: measurementsChartObj.data('data').fat,
        fill: false,
        lineTension: 0,
        borderColor: '#ffff00'
    };

    chest = {
        label: "Grudni koš",
        data: measurementsChartObj.data('data').chest,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1F1'
    };

    waist = {
        label: "Trbuh",
        data: measurementsChartObj.data('data').waist,
        fill: false,
        lineTension: 0,
        borderColor: '#ff1616'
    };

    thigh = {
        label: "Natkoljenica",
        data: measurementsChartObj.data('data').thigh,
        fill: false,
        lineTension: 0,
        borderColor: '#F1D6C4'
    };

    calf = {
        label: "Potkoljenica",
        data: measurementsChartObj.data('data').calf,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1D0'
    };

    biceps = {
        label: "Nadlaktica",
        data: measurementsChartObj.data('data').biceps,
        fill: false,
        lineTension: 0,
        borderColor: '#D4C4F1'
    };

    biceps_flex = {
        label: "Nadlaktica (fleksija)",
        data: measurementsChartObj.data('data').biceps_flex,
        fill: false,
        lineTension: 0,
        borderColor: '#F1C4EA'
    };


    measurementData = {
        labels: measurementsChartObj.data('data').labels,
        datasets: [height, weight, fat, chest, waist, thigh, calf, biceps, biceps_flex]
    };

    chartOptions = {
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 80,
                fontColor: 'black',
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio : false,
        responsive : true
    };

    lineChart = new Chart(measurementsChartObj, {
        type: 'line',
        data: measurementData,
        options: chartOptions
    });
}



function mobiExploChart() {
    measurementsChartObj = $("#measurements-chart-mobi-explo");

    // return unless Chart object for measurements exists
    if (measurementsChartObj.length == 0) {
        return;
    }

    deep_squat = {
        label: "Čučanj",
        data: measurementsChartObj.data('data').deep_squat,
        fill: false,
        lineTension: 0,
        borderColor: '##00cc66'
    };

    active_leg_lift = {
        label: "Podizanje noge",
        data: measurementsChartObj.data('data').active_leg_lift,
        fill: false,
        lineTension: 0,
        borderColor: '#000000'
    };

    shoulder_mobility = {
        label: "Mob. ramena",
        data: measurementsChartObj.data('data').shoulder_mobility,
        fill: false,
        lineTension: 0,
        borderColor: '#ffff00'
    };

    jump = {
        label: "Skok udalj",
        data: measurementsChartObj.data('data').jump,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1F1'
    };

    three_step_l = {
        label: "Troskok L",
        data: measurementsChartObj.data('data').three_step_l,
        fill: false,
        lineTension: 0,
        borderColor: '#ff1616',
        borderWidth: 3
    };

    three_step_r = {
        label: "Troskok R",
        data: measurementsChartObj.data('data').three_step_r,
        fill: false,
        lineTension: 0,
        borderColor: '#F1D6C4'
    };

    squat_jump = {
        label: "Polučučanj",
        data: measurementsChartObj.data('data').squat_jump,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1D0'
    };

    movement_jump = {
        label: "Polučučanj - priprema",
        data: measurementsChartObj.data('data').movement_jump,
        fill: false,
        lineTension: 0,
        borderColor: '#D4C4F1'
    };

    movement_jump_l = {
        label: "Jednonožni L",
        data: measurementsChartObj.data('data').movement_jump_l,
        fill: false,
        lineTension: 0,
        borderColor: '#F1C4EA'
    };

    movement_jump_r = {
        label: "Jednonožni R",
        data: measurementsChartObj.data('data').movement_jump_r,
        fill: false,
        lineTension: 0,
        borderColor: '#ccffff'
    };

    measurementData = {
        labels: measurementsChartObj.data('data').labels,
        datasets: [deep_squat, active_leg_lift, shoulder_mobility, jump, three_step_l, three_step_r, squat_jump, movement_jump, movement_jump_l, movement_jump_r]
    };

    chartOptions = {
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 80,
                fontColor: 'black',
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true
    };

    lineChart = new Chart(measurementsChartObj, {
        type: 'line',
        data: measurementData,
        options: chartOptions
    });
}


function agiStrRestChart() {
    measurementsChartObj = $("#measurements-chart-agi-str-rest");

    // return unless Chart object for measurements exists
    if (measurementsChartObj.length == 0) {
        return;
    }

    balance_l = {
        label: "Desna noga (balance)",
        data: measurementsChartObj.data('data').balance_l,
        fill: false,
        lineTension: 0,
        borderColor: '##00cc66'
    };

    balance_r = {
        label: "Lijeva noga (balance)",
        data: measurementsChartObj.data('data').balance_r,
        fill: false,
        lineTension: 0,
        borderColor: '#000000'
    };

    ift = {
        label: "30/15 IFT",
        data: measurementsChartObj.data('data').ift,
        fill: false,
        lineTension: 0,
        borderColor: '#ffff00'
    };

    sprint_5 = {
        label: "Sprint 5",
        data: measurementsChartObj.data('data').sprint_5,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1F1'
    };

    sprint_10 = {
        label: "Sprint 10",
        data: measurementsChartObj.data('data').sprint_10,
        fill: false,
        lineTension: 0,
        borderColor: '#ff1616',
        borderWidth: 3
    };

    sprint_20 = {
        label: "Sprint 20",
        data: measurementsChartObj.data('data').sprint_20,
        fill: false,
        lineTension: 0,
        borderColor: '#F1D6C4'
    };

    agility_505 = {
        label: "5 – 0 – 5",
        data: measurementsChartObj.data('data').agility_505,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1D0'
    };

    agility_kus = {
        label: "KUS",
        data: measurementsChartObj.data('data').agility_kus,
        fill: false,
        lineTension: 0,
        borderColor: '#D4C4F1'
    };

    quad_iso = {
        label: "Zadnja loža",
        data: measurementsChartObj.data('data').quad_iso,
        fill: false,
        lineTension: 0,
        borderColor: '#F1C4EA'
    };

    squat_time = {
        label: "Čučanj izdržaj",
        data: measurementsChartObj.data('data').squat_time,
        fill: false,
        lineTension: 0,
        borderColor: '#ccffff'
    };

    back_ext = {
        label: "Leđna ext",
        data: measurementsChartObj.data('data').back_ext,
        fill: false,
        lineTension: 0,
        borderColor: '#9900ff'
    };

    hollow_body = {
        label: "Hollow",
        data: measurementsChartObj.data('data').hollow_body,
        fill: false,
        lineTension: 0,
        borderColor: '#993333'
    };

    measurementData = {
        labels: measurementsChartObj.data('data').labels,
        datasets: [balance_l, balance_r, ift, sprint_5, sprint_10, sprint_20, agility_505, agility_kus, quad_iso, squat_time, back_ext, hollow_body]
    };

    chartOptions = {
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 80,
                fontColor: 'black',
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true
    };

    lineChart = new Chart(measurementsChartObj, {
        type: 'line',
        data: measurementData,
        options: chartOptions
    });
}



function dailyReportChart() {
    measurementsChartObj = $("#daily-report-chart");

    // return unless Chart object for measurements exists
    if (measurementsChartObj.length == 0) {
        return;
    }

    heart_rate = {
        label: "Grafikon srčanog ritma (bpm)",
        data: measurementsChartObj.data('data').heart_rate,
        fill: false,
        lineTension: 0,
        borderColor: '#ff1616'
    };

    measurementData = {
        labels: measurementsChartObj.data('data').labels,
        datasets: [heart_rate]
    };

    chartOptions = {
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 80,
                fontColor: 'black',
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true
    };

    lineChart = new Chart(measurementsChartObj, {
        type: 'line',
        data: measurementData,
        options: chartOptions
    });
}


$(document).on( "turbolinks:load", function() {
    measurementsChart();
    mobiExploChart();
    dailyReportChart();
    agiStrRestChart();
});






function preview_image() {
  $(".single-image-field").on('change', function () {
    file = $(this).prop('files')[0]
    parent = $(this).parents('.image-holder')
    if (file) {
      parent.find(".preview-image").attr("src", URL.createObjectURL(file))
      parent.find(".preview-holder").removeClass('d-none').fadeIn();
    } else {
      parent.find(".preview-holder").fadeOut().addClass('d-none');
    }
  });
}

function multiple_preview() {
  $(".multiple-image-field").on("change", function () {
    files = $(this).prop('files')
    parent = $(this).parents('.image-holder')
    if (files.length > 3) {
      alert("Moguće odabrati najviše 3 slike!");
      $(this).val('');
      parent.find(".preview-holder").fadeOut().addClass('d-none');
    } else {
      parent.find(".preview-holder").html('');
      var i;
      for (i = 0; i < files.length; ++i) {
        var reader = new FileReader();
        reader.onload = function (e) {
          parent.find(".preview-holder").
            append('<img src="' + e.target.result + '" class="img-fluid mx-2" style="max-width: 200px;">');
        }
        reader.readAsDataURL(files[i]);
        parent.find(".preview-holder").removeClass('d-none').fadeIn();
      }
    }
  });
}
$(document).on("turbolinks:load", function () {
  preview_image()
  multiple_preview()
});
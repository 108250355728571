function initDatatables() {
    opts = {
        'language': {
            "sEmptyTable": "Nema podataka u tablici",
            "sInfo": "Prikazano _START_ do _END_ od _TOTAL_ rezultata",
            "sInfoEmpty": "Prikazano 0 do 0 od 0 rezultata",
            "sInfoFiltered": "(filtrirano iz _MAX_ ukupnih rezultata)",
            "sInfoPostFix": "",
            "sInfoThousands": ",",
            "sLengthMenu": "Prikaži _MENU_ rezultata po stranici",
            "sLoadingRecords": "Dohvaćam...",
            "sProcessing": "Obrađujem...",
            "sSearch": "Pretraži:",
            "sZeroRecords": "Ništa nije pronađeno",
            "oPaginate": {
                "sFirst": "Prva",
                "sPrevious": "Nazad",
                "sNext": "Naprijed",
                "sLast": "Zadnja"
            },
            "oAria": {
                "sSortAscending": ": aktiviraj za rastući poredak",
                "sSortDescending": ": aktiviraj za padajući poredak"
            }
        },
        'sDom': '<"row dt-head"<"col-sm-6 text-left"f><"col-sm-6 text-right"l>>rt<"dt-bottom"<"col-sm-6 text-left"i><"col-sm-6 text-right"p>>',
        'pageLength': 100,
        "responsive": true,
        'aaSorting': []
    }
    if ($("#app .table-search").hasClass('default-sort')) {
        opts.order = [[3, "desc"]];
    }
    $("#app .table-search").DataTable(opts);
}

function deleteConfirmation() {
    $( ".delete-confirm" ).click(function() {
        return confirm('Jeste li sigurni?');
    });
}

function hideAlert() {
    $(".alert").delay(3000).slideUp(500, function(){
        $(".alert").hide();
    });
}

function initDatePicker() {
    $('.datepicker').datepicker({
        isRTL: false,
        autoclose:true,
        language: 'hr-HR',
        autoClose: true,
        autoHide: true,
        endDate: new Date()
    });
}

function initTooltips() {
    $('[data-toggle="tooltip"]').tooltip()
}

function initSelect2() {
    $('.select2').select2({
        placeholder: "Odaberite"
    });
}

function select2CacoonInit() {
    $("#workout-creator").on("cocoon:after-insert", function(_, row){
        initSelect2();
    });
}

function clearDataTable() {
    if($("#app .table-search").length == 1) {
        $("#app .table-search").DataTable().destroy();
    }
}

function focusSelect2Text() {
    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });
}

function exercisePreviewModal() {
    $('#video-preview').on('hidden.bs.modal', function () {
        $("#embed-here").html('');
    });

    $(".open-video-preview").click(function () {
        video = $(this).data('url')
        $("#embed-here").html('<iframe width="560" height="315" src="' + video + '" frameborder="0" allowfullscreen wmode="Opaque"></iframe>');
    });
}

function setRowOrderNumbers() {
    let n = 0;
    $(".counters").each(function () {
        $(this).html("#" + ++n);
    })
}

function setRowOrderNumbersOnAction() {
    $("#table-with-counters").on('draw.dt, order.dt', function () {
        setRowOrderNumbers()
    });
}

function sidebarPushToggle() {
    if ($(window).width() < 1200) {
        $('[data-widget="pushmenu"]').PushMenu("collapse");
    }
}

$(document).on( "turbolinks:load", function() {
    initDatatables()
    deleteConfirmation()
    hideAlert()
    initDatePicker()
    initTooltips()
    initSelect2()
    select2CacoonInit()
    focusSelect2Text()
    exercisePreviewModal()
    setRowOrderNumbers()
    setRowOrderNumbersOnAction()
    sidebarPushToggle()
});


$(document).on( "turbolinks:before-cache", function() {
    clearDataTable()
});
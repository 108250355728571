function expandOneWorkoutPerDayOnNewFormLoad() {
  if($('#edit-form').val() == 'false') {
    $(".add_fields").click();

    // init select2 on new form
    $('.select2').select2({
      placeholder: "Odaberite"
    });
  };
}

function destroyWeekAction() {
  wpc.on('click', '.destroy-week', function() {
    if (wpc.find('.workout-plan-week').length == 1) {
      alert("Nije moguće izbrisati zadnji tjedan");
      return;
    }
    $(this).parents('.workout-plan-week').remove();

    $("#workout-plan-creator .workout-plan-week").each(function (index) {
      week_index = index + 1;
      $(this).find('.week-index').val(week_index);
      $(this).find('.week-name').text(week_index);
    });
  });
}

function initSelect2(selector) {
  selector.select2({
      placeholder: "Odaberite"
  });
}

function resetInputFieldNamesLoop(day_index) {
    // 1. reset all the name values for all the workouts select fields under all the days
    day_of_the_week.parents('.workout-plan-day').find('.workout-day-workout').each(function(index) {
      $(this).attr('name', 'workout_plan[workout_plan_days_attributes][' + day_index + '][workout_plan_day_workouts_attributes][' + index + '][workout_id]');
      $(this).removeAttr('id');
    });

    // 2. reset all the name values for all the workouts destroy inputs under all the days
    day_of_the_week.parents('.workout-plan-day').find("input[name*='destroy']").each(function(index) {
      $(this).attr('name', 'workout_plan[workout_plan_days_attributes][' + day_index + '][workout_plan_day_workouts_attributes][' + index + '][_destroy]');
    });
}

function resetAllDayWeekFields(day_of_the_week, day_index) {
    // 1. reset name attribute on dropdown fields
    day_of_the_week.attr('name', 'workout_plan[workout_plan_days_attributes][' + day_index + '][day]');

    // 2. reset week index name attribute so it corelates to the day, and we seet value for that input field
    day_of_the_week.nextAll('.week-index').attr('name', 'workout_plan[workout_plan_days_attributes][' + day_index + '][week]');

    resetInputFieldNamesLoop(day_index);
}

function setCacoonHooks() {
  // This method is using cacoon hooks:
  $(wpc).on('cocoon:after-insert', function(event, node) {
    // After clicking on Add Activity button, we want to re-set name attributes on select dropdowns and destroy input fields

    // init select2 on new node
    initSelect2(node.find('.workout-day-workout'));

    $(wpc).find('.day-name').each(function(index) {
      day_of_the_week = $(this);
      day_index = index;

      resetInputFieldNamesLoop(day_index);

    });
  }).on("cocoon:before-remove", function(event, nodeToDelete) {
    // After clicking on remove workout button prevent deletion if its last workout in that specific day
      num_of_activities_in_day = nodeToDelete.parent('.workout-plan-day').find('.workout-day-workout:visible').length;

      if(num_of_activities_in_day == 1) {
          event.preventDefault();
          alert('Nije moguće izbrisati zadnju aktivnost u danu');
      }
  });
}

function resortDivsOnEditFormAndResetInputNames() {
  if ($('#edit-form').val() == 'true') {

    $('.clone-week, .destroy-week, .week-title').remove();
    $(".workout-plan-day").unwrap();

    var divs = $("#workout-plan-creator > .workout-plan-day");
    for (var i = 0; i < divs.length; i += 7) {
      divs.slice(i, i + 7).wrapAll("<div class='workout-plan-week py-3'></div>");
    }


    $("#workout-plan-creator").find('.day-name').each(function(index) {
      day_of_the_week = $(this);
      day_index = index;

      resetAllDayWeekFields(day_of_the_week, day_index);

    });

    $("#workout-plan-creator .workout-plan-week").each(function(index) {
      if($('#kind').val() == 'assigned') {
        $(this).prepend('<div class="btns text-right mb-2"><a class="btn btn-info clone-week" title="Kopiraj i stavi nakon ovog tjedna"><i class="far fa-clone"></i></a> <a class="btn btn-danger destroy-week" title="Izbriši"><i class="far fa-trash-alt"></i></a></div>');
      }
      $(this).prepend('<h3 class="week-title">Tjedan <span class="week-name">1</span></h3>');
      $(this).find('.week-name').text(index + 1);
      $(this).find('.week-index').val(index + 1);
    });

  }
}

function cloneWeekAction() {
  wpc.on('click', '.clone-week', function() {
    // clone parent of the button (week it belongs to)

    // destroy select2 everywhere on the page before cloning so it can be reinited
    wpc.find("select.select2-hidden-accessible").select2('destroy');

    current_week = $(this).parents('.workout-plan-week');
    current_week_clone = current_week.clone();
    current_week_clone.insertAfter(current_week);

    wpc.find('.day-name').each(function(index) {
      // after cloning the week we do the rest of the method for each Day on the form:
      day_of_the_week = $(this);
      day_index = index;

      // 1. loop trough all the divs representing the weeks (after clone) and we set values for input field
      // so we know which week does the day belong too, and we set title above the week
      $("#workout-plan-creator .workout-plan-week").each(function(index) {
        week_index = index + 1;
        $(this).find('.week-index').val(week_index);
        $(this).find('.week-name').text(week_index);
      });

      resetAllDayWeekFields(day_of_the_week, day_index);
    });

    // reinit select2 after everything has been cloned and replaced
    $('.select2').select2({
      placeholder: "Odaberite"
    });

  });
}


$(document).on("turbolinks:load", function() {
  wpc = $('#workout-plan-creator')

  expandOneWorkoutPerDayOnNewFormLoad()
  setCacoonHooks()
  cloneWeekAction()
  resortDivsOnEditFormAndResetInputNames()
  destroyWeekAction()
});